@tailwind base;
@tailwind components;
@tailwind utilities;

.slick-slide > div {
  margin: 0 5px;
}
.slick-list {
  margin: 0 -5px !important;
}

.slick-prev:before,
.slick-next:before {
  color: #777777 !important;
}

/* .slick-prev:before {
  margin-left: 5px;
} */

@layer base {
  html {
    font-family: Montserrat, system-ui, sans-serif;
    font-weight: 500;
    background-color: #fbfbfb !important;
    color: #111;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgb(250, 250, 250, 0.13);
  /* filter: blur(40%); */
  backdrop-filter: blur(0.3px);
  z-index: 40;
  /* transition: all 0.2s ease-in-out; */
}

.navDropdown::before {
  content: "";
  position: absolute;
  top: -5px;
  right: 20px;
  height: 9px;
  width: 9px;
  background-color: #fff;
  border-top: 0.5px solid #009f7f;
  border-left: 0.5px solid #009f7f;
  transform: rotate(45deg);
}

#mobileNav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  position: fixed;
  top: 0;
  right: -65%;
  width: 65%;
  height: 100vh;

  background-color: #ecf3f9;
  box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
  /* backdrop-filter: blur(40%); */
  padding: 120px 0 0 10%;
  transition: 0.4s ease-in-out;
}

#mobileNav.activeMenu {
  right: 0px;
}

#mobileFilter {
  display: flex;
  flex-direction: column;
  gap: 24px;

  position: absolute;
  top: 110%;
  left: -60%;
  width: 60%;
  z-index: 100;
  height: 70vh;
  max-width: 350px;

  margin-left: 5px;
  overflow-y: auto;
  /* padding: 24px 16px; */
  padding: 24px 16px 0px;
  border-radius: 5px;
  background-color: #aedabe;
  box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
  transition: 0.4s ease-in-out;
}

#mobileFilter.active {
  left: 0px;
}

.loader {
  /* position: relative; */
  transition: all 1s ease;
  animation: loading 1s infinite linear;
}

.adDesktop {
  display: none;
}

.block__content p {
  line-height: 30px;
  font-weight: 400;
}

.block__content h2 {
  font-size: 28px;
  font-weight: 600;
  color: #323c4d;
  margin-bottom: 20px;
}

.block__content h3 {
  font-size: 22px;
  font-weight: 400;
}

.block__content h4 {
  font-size: 20px;
  font-weight: 600;
}

.block__content figure {
  margin-block: 20px;
}

.filterDesktop::-webkit-scrollbar {
  width: 0px;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(100deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 768px) {
  .adDesktop {
    display: inline;
  }

  .adMobile {
    display: none;
  }

  .block__content p {
    font-size: 18px;
  }

  .block__content h2 {
    font-size: 34px;
  }

  .block__content h3 {
    font-size: 26px;
  }

  .block__content h4 {
    font-size: 22px;
  }
}
